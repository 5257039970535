import config from "@/config.json";
import { Anchor } from "@/modules/common/components";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSeparator,
} from "@headlessui/react";
import { Link, useLocation, useSubmit } from "@remix-run/react";
import { useEffect, useMemo } from "react";
import { HiOutlineBars3, HiXMark } from "react-icons/hi2";
import { twMerge } from "tailwind-merge";
import { useBoolean } from "usehooks-ts";
import { Logo } from "../";
import { useUser } from "@/modules/auth/hooks";
import md5 from "md5";

const items = {
  menu: [
    {
      name: "Products",
      href: "/",
      desktop: true,
    },
    {
      name: "Categories",
      href: "/categories",
      desktop: true,
    },
    {
      name: "Submit",
      href: "/submit",
      desktop: false,
    },
  ],
  user: [
    {
      name: "Settings",
      href: "/settings",
    },
  ],
};

export function Header() {
  const user = useUser();
  const location = useLocation();
  const open = useBoolean(false);
  const scroll = useBoolean(false);
  const submit = useSubmit();

  useEffect(() => {
    open.setFalse();
  }, [location]);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      scroll.setValue(window.scrollY > 50);
    });
  }, []);

  const avatar = useMemo(() => {
    if (!user) return "";

    return `https://www.gravatar.com/avatar/${md5(
      user?.email as string
    )}?s=200&d=identicon`;
  }, [user]);

  const handleSignout = () => {
    submit(null, { method: "post", action: "/signout" });

    open.setFalse();
  };

  const renderLogo = () => {
    return (
      <Link to="/" className="inline-flex items-center gap-1">
        <Logo size={32} />
        <span className="text-lg font-extrabold text-common-title sm:text-xl">
          {config.name}
        </span>
      </Link>
    );
  };

  return (
    <div
      className={twMerge(
        "z-20 bg-white sticky top-0 backdrop-blur bg-opacity-75 backdrop-filter border-b border-common-border",
        scroll.value && "shadow-soft border-common-border"
      )}
    >
      <header className="container flex items-center justify-between p-4 mx-auto lg:max-w-screen-lg">
        {renderLogo()}

        <div>
          <button
            onClick={() => open.setTrue()}
            title="Menu"
            className="inline-flex items-center md:hidden justify-center rounded-md p-2.5"
          >
            <HiOutlineBars3 className="w-7 h-7" />
          </button>

          <div className="items-center hidden gap-4 md:flex">
            <ul className="flex">
              {items.menu
                .filter((item) => item.desktop)
                .map((item) => (
                  <li key={item.href} className="flex items-center">
                    <Link
                      to={item.href}
                      className="px-3 py-2 text-sm transition-all rounded-lg hover:text-common-title hover:bg-neutral-50"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
            </ul>

            <Anchor to="/submit" variant="secondary">
              Submit
            </Anchor>

            {user ? (
              <Menu>
                <MenuButton className="overflow-hidden rounded-full">
                  <img src={avatar} alt={user.name} className="size-9" />
                </MenuButton>

                <MenuItems
                  anchor={{ to: "bottom end" }}
                  className="z-20 p-1 mt-2 overflow-hidden text-sm bg-white border shadow-lg min-w-40 border-common-border rounded-xl"
                >
                  <MenuItem>
                    <div className="px-4 py-2 text-left whitespace-nowrap">
                      {user.name}
                    </div>
                  </MenuItem>

                  {items.user.map((item) => (
                    <MenuItem key={item.href}>
                      <Link
                        to={item.href}
                        className="block w-full px-4 py-2 text-left rounded-md whitespace-nowrap hover:bg-neutral-50"
                      >
                        Settings
                      </Link>
                    </MenuItem>
                  ))}

                  <MenuSeparator className="h-[1px] my-1 bg-common-separator -mx-1" />

                  <MenuItem>
                    <button
                      onClick={handleSignout}
                      className="block w-full px-4 py-2 text-left rounded-md whitespace-nowrap hover:bg-neutral-50"
                    >
                      Sign Out
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            ) : (
              <Anchor to="/signin" variant="primary">
                Sign In
              </Anchor>
            )}
          </div>
        </div>
      </header>

      <Dialog
        as="div"
        className="md:hidden"
        open={open.value}
        onClose={() => open.setFalse()}
      >
        <div className="fixed inset-0 z-30" />

        <DialogPanel className="fixed inset-y-0 right-0 z-30 w-full overflow-y-auto bg-white">
          <div className="container mx-auto">
            <div className="flex items-center justify-between p-4">
              {renderLogo()}

              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2.5"
                onClick={() => open.setFalse()}
              >
                <HiXMark className="w-7 h-7" />
              </button>
            </div>

            <div className="p-4 pt-0 divide-y divide-common-border">
              <ul className="py-2 space-y-2">
                {items.menu.map((item) => (
                  <li key={item.name}>
                    <Link to={item.href} className="block py-2">
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>

              {user && (
                <ul className="py-2 space-y-2">
                  {items.user.map((item) => (
                    <li key={item.href}>
                      <Link to={item.href} className="block py-2">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                  <li>
                    <button
                      onClick={handleSignout}
                      className="block w-full py-2 text-left"
                    >
                      Sign out
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </div>
  );
}
